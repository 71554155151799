<template>
  <div class="shortage">
    <h2>行业痛点</h2>
    <div class="s-list">
      <div class="s-item" v-for="item in list" :key="item">
        <img :src="item.image" alt="" class="s-item-icon">
        <h4 class="s-item-title">{{ item.title }}</h4>
        <p class="s-item-desc">{{ item.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Shortage",
  data() {
    return {
      list: [
        {
          image: 'https://n.tobidad.com/site/images/showcase/icon_shortage_1.png',
          title: '广告变现策略影响用户留存',
          description: '对于游戏开发者而言，增加广告变现策略后，如何平衡中重度游戏数值、兼顾休闲超休闲游戏用户体验及留存率，是各大开发者面临的棘手问题。'
        },
        {
          image: 'https://n.tobidad.com/site/images/showcase/icon_shortage_2.png',
          title: '无法根据用户画像精细化运营',
          description: '在用户行为、地域、付费类型等特征不同的情况下，如何针对性设置广告变现运营策略，真正做到精细化运营，对开发者是不小的挑战。'
        },
        {
          image: 'https://n.tobidad.com/site/images/showcase/icon_shortage_3.png',
          title: '无法保证最佳的广告观看体验',
          description: '开发者对产品美术画面、用户体验有极高要求，若广告素材质量低下或展示不完整，将严重影响用户体验及产品调性。由此，对于广告素材的质量以及合规性的严格把控，也是开发者接入广告变现策略时的必要考量。'
        },
        {
          image: 'https://n.tobidad.com/site/images/showcase/icon_shortage_4.png',
          title: '繁琐的数据处理工作',
          description: '大部分游戏及应用均存在多渠道变现、多版本并行上线等情况。对于缺少数据中台支持的开发者，繁琐的变现数据处理工作将极大降低工作效率。'
        },
        {
          image: 'https://n.tobidad.com/site/images/showcase/icon_shortage_5.png',
          title: '多个广告平台接入适配成本高',
          description: '不同的广告平台接入方式不一致，对接文档内容参差不齐。逐一对接给开发者带来了巨大的对接、测试人力成本。'
        },
        {
          image: 'https://n.tobidad.com/site/images/showcase/icon_shortage_6.png',
          title: '买量、变现数据无法闭环',
          description: '大部分开发者通过多渠道同时买量、变现，为评估 ROI 回收率，开发者需同时关注多渠道买量及变现收益数据。繁琐、分散的数据处理极大地降低运营效率。'
        }
      ]
    }
  }
}
</script>

<style scoped>
.shortage {
  padding: 80px 0;
  background-color: rgb(250, 250, 250);
  text-align: center;
}

.shortage h2 {
  font-size: 36px;
  margin-bottom: 20px;
}

.shortage .s-list{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 1400px;
  margin: 0 auto;
}

.shortage .s-list .s-item{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 280px;
  background-color: rgb(255, 255, 255);
  margin: 10px;
  padding: 20px;
  transition: all 0.3s ease 0s;
  overflow: hidden;
}

.shortage .s-list .s-item .s-item-icon, .shortage .s-list .s-item .s-item-title, .shortage .s-list .s-item .s-item-desc {
  transition: all 0.4s ease 0s;
}

.shortage .s-list .s-item .s-item-icon {
  width: 70px;
  height: 70px;
  object-fit: contain;
}

.shortage .s-list .s-item .s-item-title {
  font-size: 20px;
  font-weight: 500;
  margin: 28px 0 10px;
}

.shortage .s-list .s-item .s-item-desc {
  max-height: 0;
  opacity: 0;
  text-align: left;
  line-height: 1.5;
  color: var(--soft-text-color);
}

.shortage .s-list .s-item:hover {
  box-shadow: 0 0 0 1px var(--primary-hover-color),0 6px 15px 3px rgba(0,0,0,.05);
}

.shortage .s-list .s-item:hover .s-item-icon {
  transform-origin: center 0;
  transform: scale(0.8);
  margin-top: -28px;
}

.shortage .s-list .s-item:hover .s-item-title {
  transform-origin: center 0;
  transform: scale(0.8);
  margin: 0 0 10px;
}

.shortage .s-list .s-item:hover .s-item-desc {
  max-height: 100px;
  opacity: 1;
}
</style>
