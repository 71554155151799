<template>
  <div class="case">
    <banner
        banner1="https://n.tobidad.com/site/videos/tools/banner1.mp4?v=1.0.1"
        banner2="https://n.tobidad.com/site/videos/tools/banner2.mp4?v=1.0.1"
        text="合作案例"
        style="height: 500px;"
    />

    <div>
      <shortage />
    </div>

    <div class="section">
      <h2>7x12小时服务开发者</h2>
      <h4>以专业高效的运营团队、稳健的技术支持团队，<br>助力开发者调优数据，驱动变现效益持续提升。</h4>
      <video class="video-block" src="https://n.tobidad.com/site/videos/showcase/7_24.mp4?v=1.0.2" poster="" preload="auto" loop autoplay muted playsinline=""></video>
    </div>


    <div class="section">
      <h2>口碑应用的一致选择</h2>
      <h4>与乐元素、迷你玩、创梦天地、盖娅互娱、西安点扣等众多国内知名厂商达成密切合作。<br>目前服务于6000万+聚合DAU，拥有800+接入开发者；收入数据ARPDAU最高提升达248%。</h4>
      <div class="vue3-marquee" style="--duration: 30s; --delay: 0s; --direction: normal; --pauseOnHover: running; --pauseOnClick: running; --loops: infinite; --gradient-color: rgba(255, 255, 255, 1), rgba(255, 255, 255, 0); --gradient-width: 15%; --min-width: 100%;">
        <div class="transparent-overlay"></div>
        <div class="overlay"></div>
        <div class="marquee">
          <div class="box" v-for="item in partners" :key="item">
            <img :src="item.image" class="app-logo" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Shortage from "@/components/Card/Shortage";
import Banner from "@/components/Card/Banner";
export default {
  name: 'Case',
  components: {Banner, Shortage},
  data() {
    return {
      partners: [
        {
          image: 'https://n.tobidad.com/site/images/showcase/app/logo_1.png'
        },
        {
          image: 'https://n.tobidad.com/site/images/showcase/app/logo_2.png'
        },
        {
          image: 'https://n.tobidad.com/site/images/showcase/app/logo_3.png'
        },
        {
          image: 'https://n.tobidad.com/site/images/showcase/app/logo_4.png'
        },
        {
          image: 'https://n.tobidad.com/site/images/showcase/app/logo_5.png'
        },
        {
          image: 'https://n.tobidad.com/site/images/showcase/app/logo_6.png'
        },
        {
          image: 'https://n.tobidad.com/site/images/showcase/app/logo_7.png'
        },
        {
          image: 'https://n.tobidad.com/site/images/showcase/app/logo_8.png'
        },
        {
          image: 'https://n.tobidad.com/site/images/showcase/app/logo_9.png'
        },
        {
          image: 'https://n.tobidad.com/site/images/showcase/app/logo_10.png'
        },
        {
          image: 'https://n.tobidad.com/site/images/showcase/app/logo_11.png'
        },
        {
          image: 'https://n.tobidad.com/site/images/showcase/app/logo_12.png'
        },
        {
          image: 'https://n.tobidad.com/site/images/showcase/app/logo_13.png'
        },
        {
          image: 'https://n.tobidad.com/site/images/showcase/app/logo_14.png'
        },
        {
          image: 'https://n.tobidad.com/site/images/showcase/app/logo_15.png'
        },
      ]
    }
  },
}
</script>

<style>
.section {
  height: auto;
  padding: 80px 0px;
}

.section h2 {
  font-size: 36px;
  margin-bottom: 10px;
}

.section h4{
  font-size: 16px;
  font-weight: 400;
  color: var(--soft-text-color);
  text-align: center;
  margin-bottom: 20px;
}

.section .video-block{
  width: 1200px;
  height: 600px;
  object-fit: contain;
}

.section .app-logo {
  width: 120px;
  height: 120px;
  margin: 60px 40px 40px;
}

.section .case-container{
  margin: 60px 0px 40px;
}


.vue3-marquee {
  overflow-x: hidden!important;
  display: flex!important;
  flex-direction: row!important;
  position: relative;
  width: 100%
}

.vue3-marquee:hover div {
  animation-play-state: var(--pauseOnHover)
}

.vue3-marquee:active div {
  animation-play-state: var(--pauseOnClick)
}

.vue3-marquee>.marquee {
  flex: 0 0 auto;
  min-width: var(--min-width);
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  animation: scroll var(--duration) linear var(--delay) var(--loops);
  animation-direction: var(--direction)
}

@keyframes scroll {
  0% {
    transform: translate(0)
  }

  to {
    transform: translate(-100%)
  }
}

.vue3-marquee>.overlay {
  position: absolute;
  width: 100%;
  height: 100%
}

.vue3-marquee>.transparent-overlay {
  position: absolute;
  width: 100%;
  height: 100%
}

.vue3-marquee>.overlay:before,.vue3-marquee>.overlay:after {
  background: linear-gradient(to right,var(--gradient-color));
  content: "";
  height: 100%;
  position: absolute;
  width: var(--gradient-width);
  z-index: 2
}

.vue3-marquee>.overlay:after {
  right: 0;
  top: 0;
  transform: rotate(180deg)
}

.vue3-marquee>.overlay:before {
  left: 0;
  top: 0
}
</style>
